import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import { mapEdgesToNodes } from '../lib/helpers'
import GraphQLErrorList from '../components/graphql-error-list'
import Project from '../components/project'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
	fragment SanityImage on SanityMainImage {
		crop {
			_key
			_type
			top
			bottom
			left
			right
		}
		hotspot {
			_key
			_type
			x
			y
			height
			width
		}
		asset {
			_id
		}
		alt
	}
  query ProjectTemplateQuery($id: String!) {
		otherProjects: allSanityProject(filter: {id: {ne: $id}}, sort: { fields: [sortOrder], order: ASC }) {
			edges {
				node {
					mainImage {
						...SanityImage
					}
					slug {
						current
					}
				}
			}
		}
    project: sanityProject(id: { eq: $id }) {
      id
      mainImage {
        ...SanityImage
      }
      title
      slug {
        current
      }
			title
			_rawBody
			_rawExcerpt
			_rawSubheader
			publishingInfo
			cta {
				title
				target
				href
			}
      sortOrder
      socialLinks {
        url
        title
        icon {
          asset {
            id
          }
        }
      }
    }
  }
`

const ProjectTemplate = props => {
  const { data, errors } = props
  const project = data && data.project
	const otherProjects = data && data.otherProjects && mapEdgesToNodes(data.otherProjects)

	return (
    <Layout>
      {errors && <SEO title='GraphQL Error' />}
      {project && <SEO title={project.title || 'Untitled'} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {project && <Project {...project} otherProjects={otherProjects}/>}
    </Layout>
  )
}

export default ProjectTemplate
